.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


/*html{
  overflow: hidden;
}*/
.dashboardButton {
  border-radius: 50%;
  transition: all 0.3s;
  width: 48px !important;
  height: 48px !important;
  max-height: 50px !important;
  padding: 2px;
}

.dashboardButton:hover {
  box-shadow: 0 0 1px 2px #6c6f72;
}

.transition {
  transition: all 0.3s;
}


.tab-pane {
  display: none;
}

.animate__animated.animate__zoomIn {
  --animate-duration: 400ms;
}

.cardslist {
  background-color: #f5f7f6;
  height: 95vh;
  overflow-y: auto;
  padding-bottom: 5px;
  width: 100%;
}

.card-header-icon-custom {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.75rem 1rem;
}

.card-content-custom {
  height: 100%;
}


.card-custom{
  width: 100%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
}

.card-custom:hover {
  box-shadow: 0 0 10px 2px rgb(10 10 10 / 30%);
}

.card-header-custom {
  min-width: 280px;
}

.card-footer-custom {
  border-top: none;
  opacity: 0;
  transition: all 0.5s;
}

.card-footer-item-custom {
  color: rgb(76, 78, 82);
}
.card-footer-item-custom:hover {
  color: rgb(187, 117, 214);
}

.card-footer-item-custom[disabled]:hover {
  color: rgb(76, 78, 82);
}
.card-footer-item-custom:not(:last-child) {
  border: none;
}

.navbar-shadow{
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
}

.dashboard_layout {
 
  padding: 1em;
  height: calc(100vh - 25px);
  overflow: scroll;
}
.sidemenu {
  padding: 1em;
  max-height: calc(100vh - 40px);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

/*Switch css starts here*/
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderThumb {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderThumb:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderThumb {
  background-color: #2196f3;
}

input:focus + .sliderThumb {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderThumb:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderThumb.round {
  border-radius: 10%;
}

.sliderThumb.round:before {
  border-radius: 10%;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.newExtensionScroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-width: 690px;
  padding: 1em;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.uploadBox {
  border: 1px dashed #2196f3;
  border-radius: 5px;
  min-height: 20px;
  box-shadow: #ccc;
}

.editorClassName {
  min-height: 250px;
  background-color: rgb(253, 253, 253);
}

.is-collapsible{
  max-height: 0px;

  overflow: hidden;
  transition: max-height 0.2s ease;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/*Blockly customization**/
.eventParam>rect{
  fill: rgb(222, 143, 108) !important;
  fill-opacity: 1.0 !important;
  stroke-width: 2;
  stroke: rgb(231, 175, 150) !important;
}

.blocklyMainBackground{
  stroke: none !important;
  stroke-width: 0;
}

/*Circular loading animation*/
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  background-color: #94979354;
  border-left: 1.1em solid #93da9f;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
